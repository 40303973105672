import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, Grid, useTheme } from "@mui/material";
import ClickableImage from "../..//layout/Interactive/Images/ClickableImage";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import StyledCard from "./StyledCard";
import {
  capitalizeWords,
  getArrayOfWords,
  getGarmentColourComponentsFromArrayOfWords,
  getGarmentColourComponentsFromObject,
  getGarmentMaterialComponentsFromObject,
} from "../../../utils/sharedFunctions";
import {
  getHexColorFromWords,
  getHexColorsFromWords,
} from "../../../utils/colorUtils";
import { getAverageSoldPrice } from "../../../utils/garmentPriceUtils";

function GarmentProductDetailsGridCard({
  garmentId,
  garmentData,
  priceData,
  ...props
}) {
  const theme = useTheme();

  const [garmentAverageResaleValue, setGarmentAverageResaleValue] =
    useState(null);
  const [garmentAverageListedValue, setGarmentAverageListedValue] =
    useState(null);
  const [garmentType, setGarmentType] = useState(null);
  const [garmentSku, setGarmentSku] = useState(null);
  const [garmentStyles, setGarmentStyles] = useState(null);
  const [garmentColors, setGarmentColors] = useState(null);
  //const [garmentColorArray, setGarmentColorArray] = useState(null) //todowp unused COLOR FROM NAME
  const [garmentEra, setGarmentEra] = useState(null);
  const [garmentGender, setGarmentGender] = useState(null);
  const [garmentMaterial, setGarmentMaterial] = useState(null);
  const [garmentCare, setGarmentCare] = useState(null);
  const [garmentColorComponents, setGarmentColorComponents] = useState(<></>);
  const [garmentMaterialComponents, setGarmentMaterialComponents] = useState(
    <></>
  );

  const typographyStyles = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: 1,
    paddingRight: 1,
    p: 1,
    color: theme.palette.primary[800],
  };

  useEffect(() => {
    if (priceData) {
      const allSoldPrices = priceData.map((x) => x["sold_price_in_gbp"]);
      setGarmentAverageResaleValue(getAverageSoldPrice(allSoldPrices));
      const allListedPrices = priceData.map((x) => x["listed_price_in_gbp"]);
      setGarmentAverageListedValue(getAverageSoldPrice(allListedPrices));
    }

    if (garmentData) {
      setGarmentType(garmentData.type);
      setGarmentSku(garmentData.vendor_code);
      setGarmentGender(garmentData.gender); //todo jack missing
      setGarmentStyles(garmentData.styles);
      setGarmentColors(garmentData.colour);
      setGarmentMaterial(garmentData.material);
      //setGarmentColorArray(getArrayOfWords(garmentData.colour)) //UNUSED COLOR FROM NAME
      if (garmentData.avg_sales_price_in_gbp) {
        setGarmentAverageResaleValue(
          Number(garmentData.avg_sales_price_in_gbp).toFixed(2)
        );
      }
      if (garmentData.avg_listed_price_in_gbp) {
        setGarmentAverageListedValue(
          Number(garmentData.avg_listed_price_in_gbp).toFixed(2)
        );
      }
      setGarmentEra(garmentData.era);
      setGarmentCare(garmentData.care_instruction);
    }
  }, [garmentData, priceData, garmentAverageResaleValue, garmentAverageListedValue]);

  /*  //todowp unused COLOR FROM NAME
  const [hexColour, setHexColour] = useState(null)
  const [hexColourArray, setHexColourArray] = useState(null)

  useEffect(() => {
    let _hexColour = null
    let _hexColours = null
    if (garmentColors)
    {
      _hexColour = getHexColorFromWords(garmentColors)
    } 
    if (garmentColorArray)
    {
      _hexColours = getHexColorsFromWords(garmentColorArray)
    } 
    setHexColour(_hexColour)
    setHexColourArray(_hexColours)
    setGarmentColorComponents(getGarmentColourComponentsFromArrayOfWords(garmentColorArray))
  }, [garmentColors, garmentColorArray])
  */

  useEffect(() => {
    setGarmentMaterialComponents(
      getGarmentMaterialComponentsFromObject(garmentMaterial)
    );
  }, [garmentMaterial]);

  useEffect(() => {
    setGarmentColorComponents(
      getGarmentColourComponentsFromObject(garmentColors)
    );
  }, [garmentColors]);

  const garmentColorIsValid =
    garmentColors &&
    (garmentColors.name ||
      (garmentColors.red && garmentColors.green && garmentColors.blue));

  return (
    <Box>
      <StyledCard title="PRODUCT DETAILS" variant="h3" bodyPadding={0}>
        <Grid container spacing={0} justifyContent={"space-between"}>
          <Grid item xs>
            <Typography sx={{ ...typographyStyles, pt: 1.5, pb: 1.5 }}>
              <Typography display="inline" sx={{ fontWeight: "bold", mr: 1 }}>
                Average Resale Value
              </Typography>
              {
                <Box
                  display="inline"
                  sx={{
                    borderRadius: 1,
                    backgroundColor: theme.palette.select[100],
                    color: theme.palette.select[400],
                    width: "auto",
                    padding: 1,
                  }}
                >
                  <Typography
                    display="inline"
                    sx={{
                      fontWeight: "bold",
                      paddingLeft: garmentAverageResaleValue ? 0 : 1,
                      paddingRight: garmentAverageResaleValue ? 0 : 1,
                    }}
                  >
                    {garmentAverageResaleValue
                      ? `£${garmentAverageResaleValue}`
                      : `-`}
                  </Typography>
                </Box>
              }
          </Typography>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mr: "-1px" }}
          />
          <Grid item xs>
            <Typography sx={{ ...typographyStyles, pt: 1.5, pb: 1.5 }}>
              <Typography display="inline" sx={{ fontWeight: "bold", mr: 1 }}>
                Average Listing Value
              </Typography>
              {
                <Box
                  display="inline"
                  sx={{
                    borderRadius: 1,
                    backgroundColor: theme.palette.select[100],
                    color: theme.palette.select[400],
                    width: "auto",
                    padding: 1,
                  }}
                >
                  <Typography
                    display="inline"
                    sx={{
                      fontWeight: "bold",
                      paddingLeft: garmentAverageListedValue ? 0 : 1,
                      paddingRight: garmentAverageListedValue ? 0 : 1,
                    }}
                  >
                    {garmentAverageListedValue
                      ? `£${garmentAverageListedValue}`
                      : `-`}
                  </Typography>
                </Box>
              }
          </Typography>
          </Grid>
        </Grid>

        {garmentType ||
        garmentSku ||
        garmentStyles ||
        garmentColorIsValid ||
        garmentEra ||
        garmentGender ||
        garmentMaterial ? (
          <Divider sx={{ minWidth: "100%" }} />
        ) : (
          <></>
        )}

        {garmentType || garmentSku ? (
          <>
            <Grid
              container
              sx={{ width: "100%" }}
              direction="row"
              justify="space-evenly"
              alignItems="flex-start"
              spacing={0}
              xs={12}
            >
              {garmentType ? (
                <>
                  <Grid item xs>
                    <Typography sx={typographyStyles}>
                      <Typography
                        display="inline"
                        sx={{ fontWeight: "bold", mr: 1 }}
                      >
                        Type
                      </Typography>
                      {garmentType ?? ""}
                    </Typography>
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ mr: "-1px" }}
                  />
                </>
              ) : (
                <></>
              )}

              {garmentSku ? (
                <Grid item xs>
                  <Typography sx={typographyStyles}>
                    <Typography
                      display="inline"
                      sx={{ fontWeight: "bold", mr: 1 }}
                    >
                      SKU
                    </Typography>
                    {garmentSku ?? ""}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            <Divider sx={{ minWidth: "100%" }} />
          </>
        ) : (
          <></>
        )}

        {garmentStyles || garmentColorIsValid ? (
          <>
            <Grid
              container
              sx={{ minWidth: "100%" }}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={0}
            >
              {garmentStyles ? (
                <>
                  <Grid item xs>
                    <Typography sx={typographyStyles}>
                      <Typography
                        display="inline"
                        sx={{ fontWeight: "bold", mr: 1 }}
                      >
                        Styles
                      </Typography>
                      {garmentStyles ?? ""}
                    </Typography>
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ mr: "-1px" }}
                  />
                </>
              ) : (
                <></>
              )}

              {garmentColorIsValid ? (
                <Grid item xs>
                  <Typography sx={typographyStyles}>
                    <Typography
                      display="inline"
                      sx={{ fontWeight: "bold", mr: 1 }}
                    >
                      Colors
                    </Typography>
                    {garmentColorComponents ?? ""}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>

            <Divider sx={{ minWidth: "100%" }} />
          </>
        ) : (
          <></>
        )}

        {garmentEra || garmentGender ? (
          <>
            <Grid
              container
              sx={{ minWidth: "100%" }}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={0}
            >
              {garmentEra ? (
                <>
                  <Grid item xs>
                    <Typography sx={typographyStyles}>
                      <Typography
                        display="inline"
                        sx={{ fontWeight: "bold", mr: 1 }}
                      >
                        Era
                      </Typography>
                      {garmentEra}
                    </Typography>
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ mr: "-1px" }}
                  />
                </>
              ) : (
                <></>
              )}

              {garmentGender ? (
                <Grid item xs>
                  <Typography sx={typographyStyles}>
                    <Typography
                      display="inline"
                      sx={{ fontWeight: "bold", mr: 1 }}
                    >
                      Gender
                    </Typography>
                    {garmentGender
                      ? garmentGender.charAt(0).toUpperCase() +
                        garmentGender.slice(1)
                      : ""}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>

            <Divider sx={{ minWidth: "100%" }} />
          </>
        ) : (
          <></>
        )}

        {garmentMaterial || garmentCare ? (
          <>
            <Grid
              container
              sx={{ minWidth: "100%" }}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={0}
            >
              {garmentMaterial ? (
                <>
                  <Grid item xs>
                    <Typography sx={typographyStyles}>
                      <Typography
                        display="inline"
                        sx={{ fontWeight: "bold", mr: 1 }}
                      >
                        Materials
                      </Typography>
                      {garmentMaterialComponents ?? ""}
                    </Typography>
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ mr: "-1px" }}
                  />
                </>
              ) : (
                <></>
              )}

              {garmentCare ? (
                <Grid item xs>
                  <Typography sx={typographyStyles}>
                    <Typography
                      display="inline"
                      sx={{ fontWeight: "bold", mr: 1 }}
                    >
                      Care
                    </Typography>
                    {garmentCare ?? ""}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>

            <Divider sx={{ minWidth: "100%" }} />
          </>
        ) : (
          <></>
        )}
      </StyledCard>
    </Box>
  );
}

export default GarmentProductDetailsGridCard;
