import { Box, Dialog, Grid, ThemeProvider, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";
import { ToolProvider, useToolContext } from "../../../contexts/ToolContext";
import { getHeaders } from "../../../utils/apiUtils";
import ImageCarousel from "../../../components/features/ImageAttribute/ImageCarousel";
import TagCarousel from "../../../components/features/ImageAttribute/TagCarousel";
import KeyEventHandler from "../../../contexts/KeyEventHandler";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import { jobNamesMap } from "../../../config/jobTypes";
import {
  showAlert,
  showAlertWithMessage,
} from "../../../components/common/userFeedback/CustomAlert";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import JobCompleteScreenCover from "../../../components/common/userFeedback/JobCompleteScreenCover";
import { getCurrentTimestamp } from "../../../utils/dateUtils";
// Create a provider for components to consume and subscribe to changes
function ImageAttributeTool() {
  const {
    inputFilters,
    jobDetails,
    toolType,
    filterHardness,
    datasets,
    jobDataLoaded,
    shownAttributes,
  } = useToolContext();
  const [tagSet, setTagSet] = useState([]);
  const [activeImage, setActiveImage] = useState(null);
  const [images, setImages] = useState([]);
  const [activeTag, setActiveTag] = useState(null);
  const [taggedList, setTaggedList] = useState({});
  const [garmentDetails, setGarmentDetails] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [jobComplete, setJobComplete] = useState(false);
  const [presentedTimeStamp, setPresentedTimeStamp] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const { trackEvent } = useAmpltiudeEventTrackingContext();

  useEffect(() => {
    if (jobDataLoaded) {
      fetchNextItem();
      fetchTagSet();
    }
  }, [jobDataLoaded]);

  useEffect(() => {
    if (taggedList && Object.keys(taggedList).length > 0) {
      setDisabled(false);
    }
  }, [taggedList]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Capture the current time for presented_timestamp and proposal_timestamp
      const proposalTimestamp = getCurrentTimestamp(); // Assuming getCurrentTimestamp() is a function you defined elsewhere

      if (event.key === " " && activeImage !== null && activeTag !== null) {
        event.preventDefault(); // prevent default tab action

        // Record the entry as an object with the image index and timestamps
        const entry = {
          imageIndex: activeImage,
          presented_timestamp: presentedTimeStamp,
          proposal_timestamp: proposalTimestamp,
        };

        setTaggedList((prevState) => ({
          ...prevState,
          [activeTag.id]: prevState[activeTag.id]
            ? [...prevState[activeTag.id], entry]
            : [entry],
        }));
      } else if (event.ctrlKey && !isNaN(event.key)) {
        const indexToRemove = parseInt(event.key) - 1;
        event.preventDefault(); // prevent default tab action

        setTaggedList((prevState) => {
          const newTaggedList = { ...prevState };

          if (
            newTaggedList[activeTag.id] &&
            indexToRemove < newTaggedList[activeTag.id].length
          ) {
            // Remove the item at indexToRemove
            newTaggedList[activeTag.id] = newTaggedList[activeTag.id].filter(
              (_, index) => index !== indexToRemove
            );
          }
          return newTaggedList;
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeImage, activeTag, taggedList]); // Add dependencies to useEffect's dependency array

  const fetchNextItem = async () => {
    const headers = await getHeaders();
    const submissionData = {
      filters: inputFilters,
      job_id: jobDetails.job_id,
      graduationConfidence: jobDetails.qa_confidence,
      toolType: toolType,
      filterHardness: filterHardness,
      pipelineJobId: jobDetails.pipeline_job_link_id,
      datasets: datasets,
    };
    axios
      .post(
        `${BASE_URL}tools/summon/next-item/basic/single`,
        submissionData,
        headers
      )
      .then((res) => {
        const parseImages = Array.from({
          length: res.data.data.num_images,
        }).map((_, index) => {
          return `${BASE_IMAGE_URL}${res.data.data.uuid_public_id}-${
            index + 1
          }.jpg`;
        });
        setGarmentDetails(res.data.data);
        setImages(parseImages);
        setDataLoading(false);
        setPresentedTimeStamp(getCurrentTimestamp());
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
        setJobComplete(true);
        setDataLoading(false);
      });
  };

  const fetchTagSet = async () => {
    const headers = await getHeaders();
    axios
      .get(
        `${BASE_URL}tools/jobs/tag-set/${jobDetails.pipeline_job_link_id}?omit_required=true`,
        headers
      )
      .then((res) => {
        console.log(res.data);
        setTagSet(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetState = () => {
    setActiveImage(null);
    setActiveTag(null);
    setTaggedList({});
    setGarmentDetails({});
    setImages([]);
  };

  async function saveData() {
    if (disabled) {
      showAlertWithMessage(
        "You must select a tag and an image to save data",
        "error"
      );
      return;
    }
    setDataLoading(true);
    setDisabled(true);
    // Your save data logic goes here
    if (Object.keys(taggedList).length === 0) {
      return;
    }
    const images = [];

    Object.keys(taggedList).forEach((key) => {
      taggedList[key].forEach((image) => {
        images.push({
          image_num: image.imageIndex + 1, // Assuming imageIndex is 0-based
          image_class: key, // Tag ID or class
          presented_timestamp: image.presented_timestamp,
          proposal_timestamp: image.proposal_timestamp,
        });
      });
    });

    const params = {
      garmentId: garmentDetails.garment_id,
      jobId: jobDetails.job_id,
      images: images,
      confidence: 0.4,
      pipelineJobId: jobDetails.pipeline_job_link_id,
    };
    let headers = await getHeaders();
    await axios
      .post(`${BASE_URL}tools/jobs/classify-images`, params, headers)
      .then((response) => {
        if (!response.data) {
          throw new Error("Tag Data not found");
        }
        trackEvent("Proposal", {
          garmentId: garmentDetails.garment_id,
          pipelineJobId: jobDetails.pipeline_job_link_id,
          pipelineName: jobDetails.pipeline_name,
          jobId: jobDetails.job_id,
          jobName: jobDetails.job,
          toolType: jobNamesMap[jobDetails.job],
          toolName: "ImageAttributeTool",
          confidence: jobDetails.qa_confidence,
          skipped: false,
          qa: false,
        });
        resetState();
        fetchNextItem();
      })
      .catch((error) => {
        //Handle All Errors Here
        console.log(error);
      });
  }

  const skipItem = async () => {
    console.log("Skipping item");
    console.log(garmentDetails);
    setDataLoading(true);
    const headers = await getHeaders();
    const submissionData = {
      tool: toolType,
      job_id: jobDetails.job_id,
      garment_id: garmentDetails.garment_id,
      confidence: 0,
      pipeline_job_id: jobDetails.pipeline_job_link_id,
    };
    await axios
      .post(`${BASE_URL}tools/jobs/skip-garment`, submissionData, headers)
      .then((res) => {
        console.log(`jobNamesMap[toolType]: ${jobNamesMap[toolType]}`);
        trackEvent("Proposal", {
          garmentId: garmentDetails.garment_id,
          pipelineJobId: jobDetails.pipeline_job_link_id,
          pipelineName: jobDetails.pipeline_name,
          jobId: jobDetails.job_id,
          jobName: jobDetails.job,
          toolType: jobNamesMap[jobDetails.job],
          toolName: "ImageAttributeTool",
          confidence: jobDetails.qa_confidence,
          skipped: true,
          qa: false,
        });
        resetState();
        console.log(res.data);
        fetchNextItem();
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
      });
  };

  return (
    <KeyEventHandler onEnter={saveData} onTab={skipItem}>
      <LoadingOverlay open={dataLoading} />

      {!jobComplete ? (
        <Grid
          container
          spacing={1}
          sx={{
            p: 1,
            height: "100%",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography
              variant="h4"
              color={"primary.800"}
              sx={{
                flex: 1,
              }}
            >
              Image Tagging Tool
            </Typography>
            <Typography variant="body1">
              Garment ID: {garmentDetails.garment_id}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <ImageCarousel
              images={images}
              setActiveImage={setActiveImage}
              activeImage={activeImage}
            />
          </Grid>
          <Grid item xs={8}>
            <TagCarousel
              tags={tagSet}
              activeTag={activeTag}
              setActiveTag={setActiveTag}
              images={images}
              taggedList={taggedList}
            />
          </Grid>
        </Grid>
      ) : (
        <JobCompleteScreenCover />
      )}
    </KeyEventHandler>
  );
}
function WrappedImageAttributeTool() {
  const { id } = useParams();
  return (
    <ToolProvider id={id}>
      <ImageAttributeTool />
    </ToolProvider>
  );
}

export default WrappedImageAttributeTool;
