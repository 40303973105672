import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";
import { showAlert } from "../../common/userFeedback/CustomAlert";

function PineconeIndexDropdown({ setIndex, index, setMetaData, disabled, sx }) {
  const [openSearchIndexes, setOpenSearchIndexes] = useState([]);

  async function getIndexes() {
    const headers = await getHeaders();
    try {
      const res = await axios.get(
        `${BASE_URL}tools/aggregation/list-indexes`,
        headers
      );
      setOpenSearchIndexes(res.data.data);
    } catch (err) {
      showAlert(err);
    }
  }

  useEffect(() => {
    getIndexes();
  }, []);

  return (
    <Autocomplete
      options={openSearchIndexes}
      getOptionLabel={(option) => option.label}
      value={openSearchIndexes.find((opt) => opt.label === index) || null} // Match the selected label
      onChange={(event, newValue) => {
        if (newValue) {
          setIndex(newValue.label); // Set the index to the label (value)
          if (typeof setMetaData === "function") {
            setMetaData(newValue); // Pass the entire object if necessary
          }
        } else {
          setIndex(""); // Clear the index if no selection
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Select Index"
          placeholder="Indexes"
        />
      )}
      disabled={disabled}
      sx={{
        minWidth: 200,
        ...sx,
      }}
    />
  );
}

export default PineconeIndexDropdown;
