import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import zIndex from "@mui/material/styles/zIndex";

export const baseTheme = {
  fonts: {
    body: "system-ui, sans-serif",
    heading: "Georgia, serif",
    monospace: "Menlo, monospace",
  },
  palette: {
    mode: "light",
    primary: {
      100: "#FFFFFF",
      200: "#FAFAFA",
      300: "#E3E3E3",
      400: "#A8A8A8",
      500: "#8C8C8C", // replaces old 'primary.main'; #666666
      600: "#5F5F5F",
      700: "#494949",
      800: "#353535",
      main: "#353535",
      darker: "#282828",
      darkest: "#111111",
    },
    secondary: {
      100: "#F4EFFF",
      300: "#D1C7E7",
      500: "#A092BB",
      800: "#726692",
      main: "#726692",
    },
    tertiary: {
      100: "#D3D1F6",
      200: "#2018d6",
      300: "#1d15bf",
      400: "#1913a8",
      500: "#161091",
      600: "#130d7a",
      700: "#0f0b63",
      800: "#0c084c",
      main: "#726692",
    },
    select: {
      100: "#D8F7DD", //#63DF77 with 20% opacity
      200: "#8EDB9B",
      300: "#63DF77",
      400: "#62926A",
      800: "#253C29",
      main: "#8EDB9B",
    },
    soldPriceLine: {
      100: "#ccff33",
      200: "#9ef01a",
      300: "#70e000",
      400: "#38b000",
      500: "#008000",
      main: "#008000",
    },
    listedPriceLine: {
      100: "#48cae4",
      200: "#00b4d8",
      300: "#0096c7",
      400: "#0077b6",
      500: "#023e8a",
      main: "#023e8a",
    },
    analytics: {
      400: "#9a1be3",
      800: "#4b0275",
      main: "#4b0275",
    },
    error: {
      main: "#FF0000",
    },
    success: {
      main: "#008000",
    },
    warning: {
      main: "#FFA500",
    },
  },
  spacing: 12,
  typography: {
    h1: {
      fontFamily: "Rigid Square",
      fontWeight: "bold",
      fontSize: "32px",
      lineHeight: "24px",
      textTransform: "uppercase",
      userSelect: "none",
    },
    h2: {
      fontFamily: "Rigid Square",
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "24px",
      textTransform: "uppercase",
      userSelect: "none",
    },
    h3: {
      fontFamily: "Rigid Square",
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "24px",
      textTransform: "uppercase",
      userSelect: "none",
    },
    h4: {
      fontFamily: "Inter",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
      textTransform: "uppercase",
      userSelect: "none",
    },
    h5: {
      fontFamily: "Inter",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      userSelect: "none",
    },
    h6: {
      fontFamily: "Inter",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      userSelect: "none",
    },
    h7: {
      fontFamily: "Inter",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      userSelect: "none",
    },
    h8: {
      fontFamily: "Inter",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      userSelect: "none",
    },
    subheading: {
      fontFamily: "Rigid Square",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
      userSelect: "none",
    },
    label: {
      fontFamily: "Rigid Square",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "24px",
      userSelect: "none",
    },
    italic: {
      fontStyle: "italic",
    },
    body_lg: {
      fontFamily: "Rigid Square",
      fontSize: "18px",
      lineHeight: "24px",
    },
    body_md: {
      fontFamily: "Rigid Square",
      fontSize: "16px",
      lineHeight: "24px",
    },
    body_sm: {
      fontFamily: "Rigid Square",
      fontSize: "14px",
      lineHeight: "20px",
    },
    body_xs: {
      fontFamily: "Rigid Square",
      fontSize: "12px",
      lineHeight: "16px",
    },
    btn_txt_lg: {
      fontFamily: "Rigid Square",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "24px",
      userSelect: "none",
    },
    btn_txt_md: {
      fontFamily: "Inter",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      userSelect: "none",
    },
    btn_txt_sm: {
      fontFamily: "Inter",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      userSelect: "none",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          h7: "h6",
          h8: "h6",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "input:focus::placeholder": {
            color: "transparent", //TODO WP we may want to remove this - hides placeholder text on focus
            marginBottom: 2,
          },
          "& label": {
            variant: "subtitle",
            zIndex: -999,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: "Rigid Square",
          fontWeight: "bold",
          fontSize: "18px",
          lineHeight: "24px",
          textTransform: "uppercase",
        },
      },
    },
    //MuiInputLabel: {
    //  defaultProps: {
    //    shrink: true
    //  }
    //},
    //Removes the border cutoff issue after moving input label
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          legend: {
            display: "none",
          },
          marginTop: -2,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#000000",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        table: {
          tbody: {
            tr: {
              "& pvtOutput": {
                div: {
                  div: {
                    div: {
                      svg: {
                        "& dragLayer": {
                          "& xy": {
                            "& nsewdrag": {
                              width: "1000px !important",
                              height: "1000px !important"
                            }
                          }
                        }
                      }
                    }
                  }
                }
              } 
            }
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        toolbarContainer: {
          pb: 1,
        },
        root: {
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            display: "none",
          },
          "& .MuiDataGrid-toolbarContainer": {
            pb: 1,
          },
          "& .MuiDataGrid-cell:hover": {
            //this is for the text on the cell on hover
          },
          "& .MuiDataGrid-cell": {
            "& .MuiTypography-root": {
              fontFamily: "Rigid Square",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#5F5F5F",
            },
          },
          border: 1,
          borderColor: "#E3E3E3",
          borderRadius: 2,
          height: "100%",
          width: "100%",
          fontFamily: "Rigid Square",
          fontSize: "18px",
          lineHeight: "24px",
          color: "#5F5F5F",
          //userSelect: "none",
          // Add more custom styles as needed
        },
        header: {
          fontFamily: "Rigid Square", // Set header font weight to bold
          fontWeight: "bold",
          // Add more custom styles for headers as needed
        },
        columnHeader: {
          color: "#8C8C8C",
          backgroundColor: "#FAFAFA",
          "& .MuiSvgIcon-root": {
            color: "#A8A8A8",
          },
        },
        columnHeaderTitle: {
          fontFamily: "Inter",
          fontWeight: "bold",
          fontSize: "16px",
          lineHeight: "24px",
          textTransform: "uppercase",
          userSelect: "none",
          // Add styles for column header titles if needed
        },
        //columnHeaderSortIcon: {
        //  color: 'white',
        //},
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "text-link" }, // Custom 'angular' variant
          style: {
            textTransform: "uppercase",
            color: "#353535",
            "&:disabled": {
              color: "#A8A8A8",
            },
            "&:hover": {
              color: "#8C8C8C",
            },
            "&:focus": {
              color: "#282828",
            },
            "&:pressed": {
              color: "#111111",
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            fontFamily: "Rigid Square",
            fontSize: "18px",
            lineHeight: "24px",
          },
        },
        {
          props: { variant: "contained", color: "primary" },
          style: {
            fontFamily: "Rigid Square",
            fontSize: "18px",
            lineHeight: "24px",
            color: "#E3E3E3 !important",
            backgroundColor: "#353535",
            "&:hover": {
              backgroundColor: "#E3E3E3", // Lighter black on hover
              color: "#353535 !important", // Lighter black on hover
            },
            "&:focused": {
              backgroundColor: "#8C8C8C", // Lighter black on hover
              color: "#353535 !important", // Lighter black on hover
            },
            "&:pressed": {
              backgroundColor: "#A8A8A8", // Lighter black on hover
              color: "#353535 !important", // Lighter black on hover
            },
            "&:disabled": {
              color: "#A8A8A8 !important", // Lighter black on hover
            },
          },
        },
        {
          props: { variant: "contained-white", color: "primary" },
          style: {
            fontFamily: "Rigid Square",
            fontSize: "18px",
            lineHeight: "24px",
            color: "#E3E3E3 !important",
            backgroundColor: "#353535",
            "&:hover": {
              backgroundColor: "#E3E3E3", // Lighter black on hover
              color: "#353535 !important", // Lighter black on hover
            },
            "&:focused": {
              backgroundColor: "#8C8C8C", // Lighter black on hover
              color: "#353535 !important", // Lighter black on hover
            },
            "&:pressed": {
              backgroundColor: "#A8A8A8", // Lighter black on hover
              color: "#353535 !important", // Lighter black on hover
            },
            "&:disabled": {
              color: "#A8A8A8 !important", // Lighter black on hover
            },
          },
        },
        {
          props: { variant: "angular" }, // Custom 'angular' variant
          style: {
            backgroundColor: "#000", // Use black from the palette
            color: "#fff", // White text
            borderRadius: "4px", // Angular appearance
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.8)", // Lighter black on hover
            },
          },
        },
        {
          props: { variant: "angular", color: "secondary" }, // Custom 'angular' variant with secondary color
          style: {
            backgroundColor: "#fff", // White background for secondary variant
            color: "#000", // Black text
            borderRadius: "4px", // Angular appearance
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.8)", // Slightly transparent white on hover
            },
          },
        },
        {
          props: { variant: "longblock", color: "primary" },
          style: {
            position: "relative",
            right: 0,
            transform: "translateY(-50%)",
            backgroundColor: "rgba(255,255,255,0.8)",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.2)",
              "& svg": {
                color: "#FFFFFF !important",
              },
            },
            borderRadius: 4,
            border: "1px solid #E3E3E3 !important",
            padding: "2rem",
            paddingRight: 0,
            paddingLeft: 0,
          },
        },
      ],
      // Default style that applies to all variants unless overridden
      styleOverrides: {
        root: {
          fontFamily: "Rigid Square",
          fontWeight: "bold",
          fontSize: "18px",
          lineHeight: "24px",
          textTransform: "none",
          userSelect: "none",
          color: "#353535",
          "&:disabled": {
            color: "#A8A8A8",
          },
          "&:hover": {
            color: "#8C8C8C",
            backgroundColor: "rgba(0,0,0,0.05)",
          },
          "&:focus": {
            color: "#282828",
          },
          "&:pressed": {
            color: "#111111",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          "& .MuiListItemText-primary": {
            fontFamily: "Rigid Square",
            fontSize: "16px",
            lineHeight: "24px",
            userSelect: "none",
          },
        },
      },
    },
  },
};

export const theme = createTheme(baseTheme); //responsiveFontSizes(createTheme(baseTheme));  /* https://mui.com/material-ui/customization/theming/#responsivefontsizes-theme-options-theme */
