import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  useTheme,
  Modal,
  CircularProgress,
  Button,
  Checkbox,
  Grid,
} from "@mui/material";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import { getHeaders } from "../../../../utils/apiUtils";
import { BASE_URL } from "../../../../config/apiConfig";
import { APP_BAR_HEIGHT } from "../../../../components/layout/AppBar/AppBarComponent";
import ImageTaggingUserProgressPivotTable from "./ImageTaggingUserProgressPivotTable";
import ImageTaggingDatasetProgressPivotTable from "./ImageTaggingDatasetProgressPivotTable";
const PlotlyRenderers = createPlotlyRenderers(Plot);

function ImageTaggingAnalyticsDashboard() {
  const { palette, typography } = useTheme();
  const [dataLoading, setDataLoading] = useState(false);
  const imageTaggingUserProgressPivotRef = useRef();
  const imageTaggingDatasetProgressPivotRef = useRef();

  const refreshData = async () => {
    imageTaggingUserProgressPivotRef.current?.refreshData();
    imageTaggingDatasetProgressPivotRef.current?.refreshData();
  };

  return (
    <>
      <Modal
        open={dataLoading}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Modal>
    <Box
    sx={{
      height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      p:2,
      paddingTop: 1
    }} 
    >
      <Box
        sx={{
          mb: 1,
          border: `1px solid ${palette.primary[200]}`,
          borderRadius: 1,
          flexDirection: "row",
          display: "flex",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            mb: 2,
          }}
        >
          Image Tagging Analytics
        </Typography>
        
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            mt: -1
          }}
        >
          <Button variant="contained" onClick={refreshData}>
            Refresh
          </Button>
        </Box>
      </Box>

      <Grid container>
          <Grid item>
            <ImageTaggingUserProgressPivotTable fillPage={false} ref={imageTaggingUserProgressPivotRef}/>
          </Grid>
          <Grid item>
            <ImageTaggingDatasetProgressPivotTable fillPage={false} ref={imageTaggingDatasetProgressPivotRef}/>
          </Grid>
      </Grid>
      

    </Box>
    </>
  );
}

export default ImageTaggingAnalyticsDashboard;
