import {
  Autocomplete,
  Avatar,
  Box,
  TextField,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid"; // Grid version 1
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NoDataScreenCover from "../../../components/common/userFeedback/NoDataScreenCover";
import GarmentPivotTable from "../../../components/features/Analytics/Garments/GarmentPivotTable";
import PriceTrendsLineChart from "../../../components/charts/PriceTrendsLineChart";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";
import { range } from "../../../utils/sharedFunctions";
import StyledDataGrid from "../../../components/ui/dataViews/StyledDataGrid";
import StyledCard from "../../../components/ui/cards/StyledCard";
import CustomLineChart from "../../../components/charts/CustomLineChart";
import StyledImageCarousel from "../../../components/ui/StyledImageCarousel";
import GarmentProductDetailCard from "../../../components/ui/cards/GarmentProductDetailCard";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import StyledGarmentGallery from "../../../components/ui/dataViews/StyledGarmentGallery";
import PriceTrendsLineChartCard from "../../../components/ui/cards/PriceTrendsLineChartCard";
import { useDataViewColumnContext } from "../../../contexts/DataViewColumnContext";
import { getClientNameFromDatasetID } from "../../../config/clientDataViewConfig";

function GarmentDashboard(props) {
  const { palette } = useTheme();
  const { id } = useParams();
  const [priceData, setPriceData] = useState([]); // [1]
  const [allPriceData, setAllPriceData] = useState([]); // [1]
  const [dataLoading, setDataLoading] = useState(true); // [2]
  const [rowCount, setRowCount] = useState(0); // [3]
  const [selectedRows, setSelectedRows] = useState([]); // [4]
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [garmentData, setGarmentData] = useState(null);
  const [lineChartXData, setLineChartXData] = useState([]);
  const [lineChartSeries, setLineChartSeries] = useState([]);
  const [firstItemData, setFirstItemData] = useState(null);
  const [garmentImages, setGarmentImages] = useState([]);

  const [tableView, setTableView] = useState(true);
  const [filterSold, setFilterSold] = useState(0);

  const [clientName, setClientName] = useState(null);
  const [garmentDatasetColumns, setGarmentDatasetColumns] = useState([]);

  const fetchData = async (page, pageSize, filterSold) => {
    setDataLoading(true);
    // Fetch data here
    const headers = await getHeaders();
    await axios
      .get(`${BASE_URL}tools/analytics/garments/price-data/${id}`, {
        params: {
          page: page + 1, // MUI DataGrid uses 0-based index, so adjust accordingly
          pageSize,
          filterSold
        },
        ...headers,
      })
      .then((res) => {
        console.log(
          `RESULT: ${JSON.stringify(res)}`
        );

        console.log(
          `res.data.data fetchData: ${JSON.stringify(res.data?.data)}`
        );

        let filteredData = res.data ? res.data.data : [];
        let filteredAllData = allPriceData;

        /* Filter data based on All/Sold/Unsold */

        // set row count BEFORE slicing
        setRowCount(res.data.total);
        setGarmentImages(
          filteredAllData.map((x) => `${BASE_IMAGE_URL}${x["uuid_public_id"]}-1.jpg`)
        );
        // slice to paginate
        //const paginatedAllData = filteredAllData.slice(
        //  page * pageSize,
        //  page * pageSize + pageSize
        //); //todowp remove manual pagination
        // set data for datagrid
        console.log(`filteredDatafilteredDatafilteredData: ${JSON.stringify(res)}`)
        setPriceData(filteredData);
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(`ERROR in fetchData: ${err}`);
        showAlert(err);
        setDataLoading(false);
      });
  };

  const fetchAllData = async () => {
    setDataLoading(true);
    // Fetch data here
    const headers = await getHeaders();

    await axios
      .get(`${BASE_URL}tools/analytics/garments/price-data/${id}`, {
        ...headers,
      })
      .then((res) => {
        console.log(res.data.data);

        const filteredData = res.data ? res.data.data : [];
        console.log(`filteredDatafilteredDatafilteredData: ${JSON.stringify(filteredData)}`)

        setAllPriceData(filteredData); //res.data.data
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(`ERROR in fetchAllData: ${err}`);
        showAlert(err);
        setDataLoading(false);
      });
  };

  const fetchGarmentData = async () => {
    setDataLoading(true);
    const headers = await getHeaders();
    axios
      .get(`${BASE_URL}tools/analytics/garments/${id}`, headers)
      .then((res) => {
        console.log(
          `res.data.data fetchGarmentData: ${JSON.stringify(res.data.data)}`
        );
        setGarmentData(res.data.data);

        let num_images = res.data.data.num_images;
        const uuid_public_id = res.data.data.uuid_public_id;
        let imageUrls = [];
        for (let i = 1; i <= num_images; i++) {
          let imageUrl = `${BASE_IMAGE_URL}${uuid_public_id}-${i}.jpg`;
          console.log(imageUrl);
          imageUrls.push(imageUrl);
        }
        setGarmentCarouselSources(imageUrls);
        setDataLoading(false);
      })

      .catch((err) => {
        console.log(`ERROR in fetchGarmentData: ${err}`);
        showAlert(err);
        setDataLoading(false);
      });
  };

  /* Source images for single garment carousel */
  const [garmentCarouselSources, setGarmentCarouselSources] = useState([]);

  useEffect(() => {
    fetchData(paginationModel.page, paginationModel.pageSize, filterSold);
  }, [paginationModel, id, filterSold, allPriceData]);

  useEffect(() => {
    fetchGarmentData();
    fetchAllData();
  }, [id]);

  
  const garmentDatasetColumnContext = useDataViewColumnContext()

  useEffect(() => {
    if (garmentDatasetColumnContext)
    {
      const config = garmentDatasetColumnContext.clientSingleGarmentViewConfig;
      if (config)
      {
        setGarmentDatasetColumns(config.columns)
      }
    }
  }, [garmentDatasetColumnContext])

  useEffect(() => {
    setLineChartXData(range(0, allPriceData.length - 1));
  }, [allPriceData, setAllPriceData, id]);

  useEffect(() => {
    setLineChartSeries([
      {
        data: allPriceData.map((x) =>
          x["sold_price_in_gbp"] === 0 ? null : x["sold_price_in_gbp"]
        ),
        color: "red",
        label: "SOLD PRICE [GBP]",
      },
      {
        data: allPriceData.map((x) =>
          x["listed_price_in_gbp"] === 0 ? null : x["listed_price_in_gbp"]
        ),
        color: "blue",
        label: "LIST PRICE [GBP]",
      },
      //{
      //  data: [7, 8, 5, 4, null, null, 2, 5.5, 1],
      //  valueFormatter: (value) => (value == null ? '?' : value.toString()),
      //},
    ]);
  }, [allPriceData, setAllPriceData, id]);

  const handleChangeViewClick = () => {
    setTableView(!tableView);
  };

  const handleSoldFilterClick = async () => {
    // 0 = 'All'
    // 1 = 'Sold'
    // 2 = 'Unsold'
    let localFilterSold = (filterSold + 1) % 3;
    setFilterSold(localFilterSold);
  };

  //TODO WP removed "No Data Loaded" message
  //if (priceData.length === 0 && dataLoading === false)
  //  return <NoDataScreenCover />; // [2]

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        padding: 2,
        paddingTop: 0,
        paddingBottom: 4,
      }}
    >
      <Typography variant="h2" sx={{ mb: 1, mt: 1 }}>
        Product Profile
      </Typography>

      <Grid container>
        <Grid item container spacing={2} sx={{ mb: 1 }} xs={12} md={12}>
          <Grid
            item
            container
            xs={12}
            md={6}
            direction="row"
            sx={{ maxHeight: "100%" }}
          >
            <StyledImageCarousel srcs={garmentCarouselSources} />
          </Grid>
          <Grid item xs={12} md={6}>
            <PriceTrendsLineChartCard data={allPriceData} />
          </Grid>
        </Grid>
        
        <Grid item container spacing={2} sx={{ marginBottom: -2, paddingBottom: 0, p: 2 }} xs={12} md={12} lg={6}>
          <GarmentProductDetailCard
            garmentId={id}
            garmentData={garmentData}
            priceData={allPriceData}
          />
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ justifyContent: "space-between", mt: 0, mb: 1 }}
        spacing={1}
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={async () => {
              await handleChangeViewClick();
            }}
            sx={{ fontWeight: "normal", float: "left" }}
          >
            <Typography
              variant="h3"
              sx={{ mr: 1 }}
              fontWeight={tableView ? "bold" : "normal"}
            >
              Table
            </Typography>{" "}
            /
            <Typography
              variant="h3"
              sx={{ ml: 1 }}
              fontWeight={!tableView ? "bold" : "normal"}
            >
              Grid
            </Typography>
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="outlined"
            onClick={async () => {
              await handleSoldFilterClick();
            }}
            sx={{ fontWeight: "normal", float: "right" }}
          >
            <Typography
              variant="h3"
              sx={{ mr: 1 }}
              fontWeight={filterSold === 0 ? "bold" : "normal"}
            >
              All
            </Typography>{" "}
            /
            <Typography
              variant="h3"
              sx={{ ml: 1, mr: 1 }}
              fontWeight={filterSold === 1 ? "bold" : "normal"}
            >
              Sold
            </Typography>{" "}
            /
            <Typography
              variant="h3"
              sx={{ ml: 1 }}
              fontWeight={filterSold === 2 ? "bold" : "normal"}
            >
              Unsold
            </Typography>
          </Button>
        </Grid>
      </Grid>

      {tableView ? (
        <StyledDataGrid
          columns={garmentDatasetColumns}
          rows={priceData}
          rowCount={rowCount}
          selectedRows={selectedRows}
          getRowId={(row) => priceData.indexOf(row)}
          pagination={true}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          keepNonExistentRowsSelected={true}
          selectionCallback={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          checkboxSelection={false}
          sx={{ }}
          onSortModelChange={(newSortModel) => {
            console.log(`newSortModel: ${JSON.stringify(newSortModel)}`)
            //setSortModel(newSortModel)
          }}
        />
      ) : (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            mb: 0,
            mt: 7,
          }}
        >
          <StyledGarmentGallery srcs={garmentImages} />
        </Box>
      )}
    </Box>
  );
}

export default GarmentDashboard;
