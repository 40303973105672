import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import { getHeaders } from "../../../utils/apiUtils";
import debuggerInstance from "../../../components/common/userFeedback/DebugComponent/debugger";
import PineconeIndexDropdown from "../../../components/forms/Dropdowns/PineconeIndexDropdown";
import { useDebugger } from "../../../contexts/DebuggerContext";
import StyledCard from "../../../components/ui/cards/StyledCard";
import StyledImageCarousel from "../../../components/ui/StyledImageCarousel";
import SingleSelectGarmentWindow from "../../../components/layout/Interactive/SingleSelectGarmentWindow";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import CustomModal from "../../../components/common/userFeedback/CustomModal";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";

import DatasetDropdown from "../../../components/forms/Dropdowns/DatasetDropdown";
import TakeActionScreenCover from "../../../components/common/userFeedback/TakeActionScreenCover";
import ImageIndexButtons from "../../../components/ui/buttons/ImageIndexButtons";

function CataloguingTool(props) {
  const { palette, typography } = useTheme();

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [imageIndex, setImageIndex] = useState(1);

  const { trackEvent } = useAmpltiudeEventTrackingContext();

  const [catalogGarments, setCatalogGarments] = useState([]);
  const [seedGarment, setSeedGarment] = useState({});
  const [seedGarmentImages, setSeedGarmentImages] = useState([]);
  const [selectedCatalogueItem, setSelectedCatalogueGarment] = useState({});
  const [metaData, setMetaData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [catalogueExhausted, setCatalogueExhausted] = useState(false);
  const [associateGarmentModalOpen, setAssociatedGarmentModalOpen] =
    useState(false);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [openSearchIndex, setOpenSearchIndex] = useState(null);
  const [numberOfColumns, setNumberOfColumns] = useState(4);

  const { addLog } = useDebugger();

  // Lifecycle Methods
  useEffect(() => {
    if (openSearchIndex) {
      fetchSeedGarment();
    }
  }, [selectedDataset, openSearchIndex]);
  useEffect(() => {
    if (
      selectedCatalogueItem &&
      Object.keys(selectedCatalogueItem).length > 0
    ) {
      setAssociatedGarmentModalOpen(true);
    }
  }, [selectedCatalogueItem]);

  // Axios methods
  const fetchSeedGarment = async () => {
    const headers = await getHeaders();
    setShowLoader(true);
    setButtonDisabled(true);
    setPage(1);
    setImageIndex(1);
    let url = `${BASE_URL}tools/summon/next-item/basic/cataloguing?`;
    if (selectedDataset) {
      url += `dataset_id=${selectedDataset.id}&`;
    }
    if (openSearchIndex) {
      url += `index=${openSearchIndex}&`;
    }

    axios
      .get(url, headers)
      .then((res) => {
        resetState();

        addLog(res.data.data.groupRepresentative);
        setSeedGarment(res.data.data.groupRepresentative);
        setMetaData(res.data.data);
        let { num_images, uuid_public_id } = res.data.data.groupRepresentative;
        let imageUrls = [];
        for (let i = 1; i <= num_images; i++) {
          let imageUrl = `${BASE_IMAGE_URL}${uuid_public_id}-${i}.jpg`;
          imageUrls.push(imageUrl);
        }
        addLog(res.data.data);
        setSeedGarmentImages(imageUrls);
        queryCatalog({
          vector: res.data.data.groupRepresentative.vector,
          offset: 1,
          page_size: pageSize,
          index: res.data.data.groupRepresentative.index,
        });
        setShowLoader(false);
        setButtonDisabled(false);
      })

      .catch((err) => {
        showAlert(err);
        setShowLoader(false);
        setButtonDisabled(false);
      });
  };

  const queryCatalog = async (params) => {
    const headers = await getHeaders();
    setButtonDisabled(true);
    setShowLoader(true);
    axios
      .post(`${BASE_URL}tools/cataloguing/querypinecone`, params, headers)
      .then((res) => {
        setCatalogGarments(res.data.data);
        setShowLoader(false);
        setButtonDisabled(false);
        if (res.data.data.length == pageSize) {
          setCatalogueExhausted(false);
        } else {
          setCatalogueExhausted(true);
        }
      })
      .catch((err) => {
        showAlert(err);
        setShowLoader(false);
        setButtonDisabled(false);
      });
  };

  const createNewCatalogItem = async () => {
    const headers = await getHeaders();
    const params = {
      index: seedGarment.index,
      vector: seedGarment.vector,
      garment_id: seedGarment.garment_id,
      uuid_public_id: seedGarment.uuid_public_id,
      action: "CATALOGUE",
    };
    setShowLoader(true);
    setButtonDisabled(true);

    axios
      .post(`${BASE_URL}tools/classify/catalogue/new-item`, params, headers)
      .then((res) => {
        resetState();
        fetchSeedGarment();
        setButtonDisabled(false);
      })
      .catch((err) => {
        showAlert(err);
        setButtonDisabled(false);
      });
  };

  const asssociateToCatalogItem = async () => {
    const headers = await getHeaders();
    const params = {
      index: seedGarment.index,
      garment_id: seedGarment.garment_id,
      associated_garment: {
        ...selectedCatalogueItem,
        index: selectedCatalogueItem.index + pageSize * (page - 1),
      },
      uuid_public_id: seedGarment.uuid_public_id,
      associated_garment_id: selectedCatalogueItem.garment_id,
      action: "AGGREGATE",
    };
    setShowLoader(true);
    setButtonDisabled(true);
    axios
      .post(`${BASE_URL}tools/classify/catalogue/associate`, params, headers)
      .then((res) => {
        resetState();
        fetchSeedGarment();
        setButtonDisabled(false);
      })
      .catch((err) => {
        showAlert(err);
        setButtonDisabled(false);
      });
  };

  // Functions
  const handleNext = () => {
    queryCatalog({
      vector: seedGarment.vector,
      offset: page + 1,
      page_size: pageSize,
      index: seedGarment.index,
    });
    setPage(page + 1);
  };

  const handleConfirm = () => {
    asssociateToCatalogItem();
    setAssociatedGarmentModalOpen(false);
  };

  const handleCancel = () => {
    setSelectedCatalogueGarment({});
    setAssociatedGarmentModalOpen(false);
  };

  const resetState = () => {
    setSeedGarment({});
    setButtonDisabled(true);
    setSeedGarmentImages([]);
    setMetaData({});
    setCatalogGarments([]);
    setPage(1);
  };
  return (
    <Box
      sx={{
        p: 1,
        pt: 0,
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
      }}
    >
      <Grid
        container
        sx={{
          p: 1,
          pt: 0,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h3">Cataloguing Tool</Typography>
          <Button
            variant="contained"
            color="success"
            onClick={handleNext}
            disabled={buttonDisabled || catalogueExhausted}
          >
            Next Page
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            pb: 2,
          }}
        >
          <DatasetDropdown
            dataset={selectedDataset}
            setDataset={setSelectedDataset}
            sx={{ mr: 2 }}
          />
          <PineconeIndexDropdown
            index={openSearchIndex}
            setIndex={setOpenSearchIndex}
          />
          <Select
            value={numberOfColumns}
            onChange={(e) => setNumberOfColumns(e.target.value)}
            displayEmpty
            sx={{ ml: 2 }}
          >
            <MenuItem value={4}>4 Column</MenuItem>
            <MenuItem value={5}>5 Columns</MenuItem>
            <MenuItem value={6}>6 Columns</MenuItem>
            <MenuItem value={7}>7 Columns</MenuItem>
            <MenuItem value={8}>8 Columns</MenuItem>
          </Select>
        </Grid>

        {openSearchIndex ? (
          <>
            <Grid item xs={3}>
              <StyledCard title="Group Representative">
                <StyledImageCarousel srcs={seedGarmentImages} />

                <Typography variant="h4" sx={{ mb: 1, mt: 2 }}>
                  {seedGarment.title}
                </Typography>
                <Typography variant="body">
                  UUID: {seedGarment.uuid_public_id}
                </Typography>
              </StyledCard>
            </Grid>
            <Grid item xs={9}>
              <SingleSelectGarmentWindow
                selectedGarment={selectedCatalogueItem}
                setSelectedGarment={setSelectedCatalogueGarment}
                garments={catalogGarments}
                imageIndex={imageIndex}
                scrollPreview={true}
                numberOfColumns={numberOfColumns}
                additionalComponents={[
                  <Grid
                    item
                    xs={2}
                    onClick={(e) => {
                      createNewCatalogItem();
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      maxHeight: "200px",
                      width: "100%",
                      overflow: "hidden",
                      cursor: "pointer",
                      backgroundColor: palette.primary[200],
                      border: `1px solid ${palette.primary[300]}`,
                      borderRadius: 2,
                      p: 2,
                    }}
                  >
                    <Typography variant="body_sm">
                      Create new Catalogue Item
                    </Typography>
                  </Grid>,
                ]}
              />
            </Grid>
          </>
        ) : (
          <TakeActionScreenCover />
        )}
        <CustomModal
          showModal={associateGarmentModalOpen}
          title="Confirm Association"
          body="Are you sure you want to associate the selected garment?"
          onDismiss={handleCancel}
          onConfirm={handleConfirm}
          onDismissTitle="Cancel"
          onConfirmTitle="Confirm"
        />
        <LoadingOverlay open={showLoader} />
      </Grid>
    </Box>
  );
}

export default CataloguingTool;
