import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";
import { showAlert } from "../../common/userFeedback/CustomAlert";

function DatasetDropdown({ setDataset, dataset, disabled, sx }) {
  const [datasets, setDatasets] = useState([]);
  async function getDatasets() {
    const headers = await getHeaders();
    try {
      const res = await axios.get(
        `${BASE_URL}tools/admin/datasets/listall`,
        headers
      );
      console.log("datasets", res.data.data);
      setDatasets(res.data.data);
    } catch (error) {
      console.log(error);
      showAlert(error);
    }
  }
  useEffect(() => {
    getDatasets();
  }, []);

  return (
    <Autocomplete
      options={datasets}
      getOptionLabel={(option) => option.name}
      value={dataset}
      onChange={(event, newValue) => setDataset(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Select dataset"
          placeholder="Datsets"
        />
      )}
      sx={{
        minWidth: 200,
        ...sx,
      }}
    />
  );
}

export default DatasetDropdown;
