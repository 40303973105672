import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  useTheme,
  Modal,
  CircularProgress,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SearchableMultiSelect from "../../../../components/forms/Dropdowns/SearchableMultiSelect";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import { getHeaders } from "../../../../utils/apiUtils";
import { BASE_URL } from "../../../../config/apiConfig";
import useSize from "../../../../hooks/useSize";
import { showAlert } from "../../../../components/common/userFeedback/CustomAlert";
import StatusMultiSelect from "../../../../components/forms/Dropdowns/StatusMultiSelect";
import PipelineJobMultiSelect from "../../../../components/forms/Dropdowns/PipelineJobMultiSelect";
const PlotlyRenderers = createPlotlyRenderers(Plot);

const ImageTaggingDatasetProgressPivotTable = forwardRef(({fillPage=false, sx={}, width=null, height=null}, ref) => {
  const [imageTaggingDatasetProgressPivotData, setImageTaggingDatasetProgressPivotData] = useState([]);
  const [pivotState, setPivotState] = useState({});
  const { palette, typography } = useTheme();
  const [date, setDate] = React.useState([dayjs().subtract(1, "day"), dayjs()]);
  const [dataLoading, setDataLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [proposalStatuses, setProposalStatuses] = useState([
    { name: "APPROVED", value: "APPROVED" },
    { name: "PENDING", value: "PENDING" },
    { name: "DECLINED", value: "DECLINED" },
  ]);
  const windowSize = useSize();
  const chartWidth = fillPage ? windowSize[0] : (width ?? 0.5*windowSize[0])
  const chartHeight = fillPage ? 0.3*windowSize[1] : (height ?? 0.25*windowSize[1])
  const itemsPerPage = 4000

  const refreshData = async () => {
    setImageTaggingDatasetProgressPivotData([]);
    setDataLoading(true);
    await fetchData()
  };

  useImperativeHandle(ref, () => {
    return {
      refreshData
    }
  })

  const fetchJobs = async () => {
    let headers = await getHeaders();
    axios
      .get(`${BASE_URL}tools/jobs/all-jobs`, headers)
      .then((res) => {
        // Reset form fields (optional)
        setJobs(res.data.data.filter(pipelineJob => pipelineJob.pipeline_job_id === 259));
      })
      .catch((err) => {
        showAlert(err);
      });
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    const pivotState = {
      data: imageTaggingDatasetProgressPivotData,
      rows: ['image_class'],
      cols: ['hourly_interval', 'qa_status', 'qa_by'],
      aggregatorName: 'Count Unique Values',
      vals: ['garment_id'],
      rendererName: 'Table',
      //sorters: {
      //    Meal: sortAs(['Lunch', 'Dinner']),
      //    'Day of Week': sortAs([
      //        'Thursday',
      //        'Friday',
      //        'Saturday',
      //        'Sunday',
      //    ]),
      //},
      plotlyOptions: {width: chartWidth, height: chartHeight}
      //plotlyConfig: {},
      //tableOptions: {
      //    clickCallback: function(e, value, filters, pivotData) {
      //        var names = [];
      //        pivotData.forEachMatchingRecord(filters, function(
      //            record
      //        ) {
      //            names.push(record.Meal);
      //        });
      //        alert(names.join('\n'));
      //    },
      //},
    }
    console.log(`imageTaggingDatasetProgressPivotData: ${JSON.stringify(imageTaggingDatasetProgressPivotData)}`)
    setPivotState(pivotState)
  }, [imageTaggingDatasetProgressPivotData])

  const fetchData = async (page = 1) => {
    setDataLoading(true);
    try {
      let headers = await getHeaders();
      const response = await axios.post(
        `${BASE_URL}tools/analytics/pivots/tagging/image-tagging-user-progress`,
        {
          startDate: date[0].toISOString(),
          endDate: date[1].toISOString(),
          page: page,
          limit: itemsPerPage,
          pipelineJobIDs: jobs.map(pipelineJob => pipelineJob.pipeline_job_id),
          qaStatuses: proposalStatuses.map((ps) => ps.name)
        },
        headers
      );

      setImageTaggingDatasetProgressPivotData((prev) => [...prev, ...response.data.data]);

      // Check if there are more items to fetch
      if (response.data.data.length === itemsPerPage) {
        await fetchData(page + 1); // Fetch next page
      } else {
        setDataLoading(false);
      }

      //return imageTaggingDatasetProgressPivotData;
    } catch (error) {
      setDataLoading(false);
      console.error(`Error fetching proposals for Image Tagging Dataset Progress:`, error);
      //return []; // Return whatever was fetched before the error
    }
  };

  useEffect(() => {
    refreshData()
  }, [date, jobs, proposalStatuses])

  return (
    <>
      <Modal
        open={dataLoading}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Modal>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          overflow: "auto",
          p: 1,
          flexDirection: "column",
        }}
      >
        <Typography variant={fillPage ? "h2" : "h4"} sx={{
          p: 1, pt: 0
        }} color="primary.800">
          Image Tagging Dataset Progress Pivot Table
        </Typography>
        <Box
          sx={{
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            flexDirection: "row",
            display: "flex",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              localeText={{ start: "Start", end: "End" }}
              onChange={(newValue) => setDate(newValue)}
              value={date}
            />
          </LocalizationProvider>

          <PipelineJobMultiSelect
              sx={{mt: -0.5, ml: 2}}
              selected={jobs}
              onSelectedChange={setJobs}
              label={"Select Pipeline Jobs"}
            />

          <StatusMultiSelect
            sx={{mt: -0.5, ml: 2}}
            selected={proposalStatuses}
            onSelectedChange={setProposalStatuses}
            label={"Select Proposal Status"}
          />

          {!ref && 
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" onClick={refreshData}>
              Refresh
            </Button>
          </Box>}
          
        </Box>

        <Box
          sx={{
            mb: 1,
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            ...sx
          }}
        >
          <PivotTableUI
            onChange={setPivotState}
            {...pivotState}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          />
        </Box>
      </Box>
    </>
  );
})

export default ImageTaggingDatasetProgressPivotTable;
