import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { getHeaders } from "../../../utils/apiUtils";
import { BASE_URL } from "../../../config/apiConfig";
import { useNavigate } from "react-router-dom";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";
import StyledDataGrid from "../../../components/ui/dataViews/StyledDataGrid";
function DetailPanelContent({ row: rowProp }) {
  return (
    <Box
      sx={{
        p: 1,
      }}
    >
      <Typography variant="h3" gutterBottom component="div">
        {rowProp.bridge_user_group}
      </Typography>
      <Typography variant="body2" gutterBottom component="div" sx={{mb: 2}}>
        {rowProp.description}
      </Typography>

      <StyledDataGrid
        density="compact"
        columns={[
          { field: "id", headerName: "id", flex: 1 },
          { field: "bridge_user", headerName: "Name", flex: 1 },
        ]}
        rows={rowProp.users}
        sx={{ flex: 1 }}
        hideFooter
      />
    </Box>
  );
}
function UserGroupsDashboard() {
  const [users, setUsers] = useState([]);
  const [includeEmptyGroups, setIncludeEmptyGroups] = useState(false);
  const navigate = useNavigate();
  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );
  const theme = useTheme();

  const getDetailPanelHeight = React.useCallback(() => 300, []);

  useEffect(() => {
    const fetchUsers = async () => {
      let headers = await getHeaders();
      axios
        .get(`${BASE_URL}tools/admin/user-groups?extended=true${includeEmptyGroups && `&includeEmpty=${includeEmptyGroups}`}`, headers)
        .then((res) => {
          // Reset form fields (optional)
          console.log(res.data.data);
          setUsers(res.data.data);
        })
        .catch((err) => {
          alert(err.response.data);
        });
    };

    fetchUsers();
  }, [includeEmptyGroups]);

  const columns = [
    {
      field: "bridge_user_group",
      headerName: "Name",
      flex: 1,
      editable: false,
    },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      editable: false,
    },

    // Add more columns as needed based on your SQL table structure
  ];

  return (
    <Box
      sx={{
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        width: "100%",
        padding: 2,
        paddingTop: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Typography variant="h2">User Groups Dashboard</Typography>

        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("create");
            }}
            sx={{mr: 2}}
          >
            New User Group
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIncludeEmptyGroups(!includeEmptyGroups)
            }}
            sx={[{
              //overflow: "hidden",
              //whiteSpace: "nowrap"
              },
              includeEmptyGroups && {
                  backgroundColor: `${theme.palette.primary[300]} !important`,
                  color: `${theme.palette.primary.main} !important`
              }]}
          >
            {includeEmptyGroups ? `Hide` : `Show`} Empty Groups
          </Button>
        </Box>
      </Box>

      <StyledDataGrid
        rows={users}
        columns={columns}
        getRowId={(row) => row.id}
        pageSize={5}
        disableRowSelectionOnClick
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
      />
    </Box>
  );
}

export default UserGroupsDashboard;
