import React, { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import { getCurrentTimestamp } from "../../../utils/dateUtils";
import ClickableImage from "./Images/ClickableImage";
import ImageScrollPreview from "./Images/ImageScrollPreview";
import ScrollImageModal from "../../common/userFeedback/modals/ScrollImageModal";

function SingleSelectGarmentWindow({
  selectedGarment,
  setSelectedGarment,
  disabled = false,
  additionalComponents = [],
  garments,
  imageIndex = 1,
  numberOfColumns=6,
  scrollPreview = false,
  spacing=2
}) {
  const { palette, typography } = useTheme();

  const [open, setOpen] = useState(false);
  const [selectedGarmentImages, setSelectedGarmentImages] = useState(null);

  const handleOpen = (garmentImages) => {
    setSelectedGarmentImages(garmentImages)
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedGarmentImages(null)
    setOpen(false);
  }

  return (
    <>
    <Grid container spacing={spacing} sx={{ml: 1, mt: -1}}>
      {garments.map((garment, index) => {
        const isSelected = selectedGarment.garment_id === garment.garment_id;
        const garmentImages = Array.from({ length: garment.num_images }, (_, i) => i).map(num => `https://scraped-garment-images.s3.eu-west-2.amazonaws.com/${garment.uuid_public_id}-${num+1}.jpg`);
        return (
          <Grid
            item
            xs={12/(numberOfColumns)-0.2}
            key={garment.garment_id}
            onClick={(e) => {
              setSelectedGarment({ ...garment, index: index + 1 });
            }}
            
            onContextMenu={(e) => {
              e.preventDefault();
              handleOpen(garmentImages);
            }}
            sx={{
              mr: 1,
              mb: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              backgroundColor: palette.primary[200],
              borderRadius: 2,
              p: scrollPreview ? 0 : 2,
              pt: scrollPreview ? 0 : 2,
              border: isSelected
                ? "3px solid green"
                : `1px solid ${palette.primary[300]}`,
              maxHeight: "200px",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            {!scrollPreview ?
            <ClickableImage
              image={garmentImages[0]}
            /> : 
            <ImageScrollPreview images={garmentImages} sx={{p: 1, display: "inline-flex", ml: "-6vw", mt: "-14vh", mr: "-4.5vw", width: "125%", mb: "-10vh"}} spacing={0.5}/>}

            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: 100,
                height: 20,
                ml: 1,
                backgroundColor: palette.primary[100]
              }}
            >
              <Typography variant="body_sm">{garment.score}</Typography>
            </Box>
          </Grid>
        );
      })}
      {additionalComponents.map((component) => {
        return component;
      })}
    </Grid>
    
    <ScrollImageModal
      handleClose={handleClose}
      openModal={open}
      images={selectedGarmentImages}
      sx={{}}
    />
    </>
  );
}
export default SingleSelectGarmentWindow;
